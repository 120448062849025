import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarChart from '../SimpleBarChart';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { useDisplayUnit } from '@hooks';

function PowerManagementChart({ loading, series, categories }) {
  const { displayUnit } = useDisplayUnit();
  return (
    <SimpleBarChart
      primaryTitleOffsetY={330}
      loading={loading}
      vertical
      primaryMaxValue={100}
      primaryXAxisLabel={'%'}
      categories={categories}
      series={series}
      customTooltipContent={(series, dataPointIndex) => (
        <KeyValueTooltip
          displayUnit={displayUnit}
          label={categories[dataPointIndex]}
          value={series[0][dataPointIndex]}
          unit={'%'}
        />
      )}
      id="power-management-chart"
    />
  );
}

PowerManagementChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool
};

PowerManagementChart.defaultProps = {
  series: [],
  categories: [],
  loading: false
};

export default PowerManagementChart;
