import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  MonetizationOnOutlinedIcon,
  Paper,
  RestoreIcon,
  Skeleton,
  Stack,
  TrendingDownIcon,
  TrendingUpIcon,
  Typography,
  Box
} from '@esgian/esgianui';
import PeriodRangeButtons from '@components/Inputs/PeriodRangeButtons/PeriodRangeButtons';
import { fetchAnalyticsData } from '@api/Rig';
import moment from 'moment/moment';
import { API_ROLLUP, DISPLAY_PERIOD, DISPLAY_UNIT, UTC_TIMESTAMP } from '@constants';
import InsightDisplayValue from '@components/Sections/Dashboard/Insights/InsightDisplayValue';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { useDisplayUnit } from '@hooks';
import {
  setInsightsRange,
  getInsightsRange
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getDollarAmountReadable } from '@helpers';
import { useQueries } from '@tanstack/react-query';

const RedArrowSVG = () => <TrendingUpIcon color={'error'} fontSize={'small'} />;

const GreenArrowSVG = () => <TrendingDownIcon color={'success'} fontSize={'small'} />;

const checkTruthy = (data) => {
  return data !== undefined && data !== null && data !== false && !Number.isNaN(data);
};

const displayArrow = (firstData, lastData) => {
  return !(!checkTruthy(firstData) && checkTruthy(lastData));
};

const calculatePercentageChange = (current, previous) => {
  if (current === previous || (current === 0 && previous === 0) || (!current && !previous)) {
    return {
      percentageChange: '0%',
      arrow: null,
      color: 'disabled'
    };
  } else if (!checkTruthy(current) && checkTruthy(previous)) {
    return {
      percentageChange: '100%',
      arrow: <GreenArrowSVG />,
      color: 'success'
    };
  } else {
    const percentageChange = Math.round((current / previous - 1) * 100);
    const arrow = percentageChange < 0 ? <GreenArrowSVG /> : <RedArrowSVG />;
    const color = percentageChange < 0 ? 'success' : 'error';

    return {
      percentageChange: `${Math.abs(percentageChange)}%`,
      arrow,
      color
    };
  }
};

const processData = (result) => {
  let items = [];
  if (Array.isArray(result)) {
    items = result.flat();
  } else {
    items = Object.values(result)
      ?.map((item) => [...item])
      .flat();
  }
  return items.reduce((basket, value) => {
    for (const [key, val] of Object.entries(value || {})) {
      if (!basket[key]) {
        basket[key] = 0;
      }
      basket[key] += val;
    }
    return basket;
  }, {});
};

function Insights() {
  const dispatch = useDispatch();
  const { valueWithUnit } = useDisplayUnit();
  const selectedRange = useSelector(getInsightsRange);

  const results = useQueries({
    queries: [
      {
        queryKey: ['insight', 'current', { selectedRange }],
        placeholderData: {},
        queryFn: ({ signal }) => {
          let startOf = 'day';
          if (selectedRange.unit === 'days' && selectedRange.number === 1) {
            startOf = 'hour';
          }
          const endDate = moment.utc().startOf(startOf);
          const startDate = endDate.clone().subtract(selectedRange?.number, selectedRange?.unit);

          return fetchAnalyticsData(
            signal,
            {
              selectedRig: { rigName: '', rigId: 0 },
              startDate: startDate.format(UTC_TIMESTAMP),
              endDate: endDate.format(UTC_TIMESTAMP)
            },
            API_ROLLUP.MIN
          )
            .then((resp) => {
              if (resp) {
                return processData(resp);
              }
              return {};
            })
            .catch(() => {});
        }
      },
      {
        queryKey: ['insight', 'previous', { selectedRange }],
        placeholderData: {},
        queryFn: ({ signal }) => {
          let startOf = 'day';
          if (selectedRange.unit === 'days' && selectedRange.number === 1) {
            startOf = 'hour';
          }
          const endDate = moment
            .utc()
            .startOf(startOf)
            .subtract(selectedRange?.number, selectedRange?.unit);
          const startDate = endDate.clone().subtract(selectedRange?.number, selectedRange?.unit);
          return fetchAnalyticsData(
            signal,
            {
              selectedRig: { rigName: '', rigId: 0 },
              startDate: startDate.format(UTC_TIMESTAMP),
              endDate: endDate.format(UTC_TIMESTAMP)
            },
            API_ROLLUP.MIN
          )
            .then((resp) => {
              if (resp) {
                return processData(resp);
              }
              return {};
            })
            .catch(() => {});
        }
      }
    ]
  });

  let loading = results.some((item) => item.isFetching);

  const {
    costSavingTrend,
    runTimeSavingTrend,
    energySavingTrend,
    fuelSavingTrend,
    co2SavingTrend,
    noxSavingTrend
  } = useMemo(() => {
    if (loading) return {};
    const { data } = results[0];
    const { data: prevData } = results[1];
    const costSaving = calculatePercentageChange(data?.costSaving, prevData?.costSaving);
    const runTimeSaving = calculatePercentageChange(data?.runTimeSaving, prevData?.runTimeSaving);
    const energySaving = calculatePercentageChange(data?.energySaving, prevData?.energySaving);
    const fuelSaving = calculatePercentageChange(data?.fuelSaving, prevData?.fuelSaving);
    const co2Saving = calculatePercentageChange(data?.co2Saving, prevData?.co2Saving);
    const noxSaving = calculatePercentageChange(data?.noxSaving, prevData?.noxSaving);
    return {
      costSavingTrend: costSaving,
      runTimeSavingTrend: runTimeSaving,
      energySavingTrend: energySaving,
      fuelSavingTrend: fuelSaving,
      co2SavingTrend: co2Saving,
      noxSavingTrend: noxSaving
    };
  }, [loading]);

  const setRangeData = (val) => {
    dispatch(setInsightsRange(val));
  };
  const { data = {} } = results[0];
  const { data: prevData = {} } = results[1];

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2} id="fleet-insight">
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Box>
              <Typography variant={'h6'}>Fleet Insight</Typography>
              <TextWithTooltipIcon
                tooltipText={
                  'Sum of all rigs with GPR Live. The time period of the metrics can be selected to the right to display last 24hrs, last 7 days etc.'
                }
                label={<Typography variant={'body2'}>Sum for all GP connected rigs</Typography>}
              />
            </Box>
          </Stack>
          <PeriodRangeButtons
            options={[
              DISPLAY_PERIOD.LAST_DAY,
              DISPLAY_PERIOD.LAST_WEEK,
              DISPLAY_PERIOD.LAST_MONTH,
              DISPLAY_PERIOD.LAST_YEAR
            ]}
            onClick={setRangeData}
            value={selectedRange}
            insights
          />
        </Stack>
        <Stack
          direction={'row'}
          divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}
          spacing={2} id="fleet-insight-kpis">
          <Stack direction={'row'} spacing={2} sx={{ pr: 2 }} >
            <Stack sx={{ p: 2 }} direction={'row'} spacing={1} id="potential-cost-savings">
              <MonetizationOnOutlinedIcon
                sx={{ fill: ({ palette }) => palette.text.primary }}
                color={'inherit'}
              />
              <Stack>
                <Typography variant={'body2'}>Potential cost savings</Typography>
                {loading ? (
                  <Skeleton height={'3em'} />
                ) : (
                  <Stack direction={'row'} spacing={0.5}>
                    <Typography variant={'h5'} bold>
                      {isNaN(data.costSaving) ? '0' : getDollarAmountReadable(data.costSaving)} USD
                    </Typography>
                    {displayArrow(prevData?.costSaving, data?.costSaving) && (
                      <Stack direction={'row'} sx={{ alignItems: 'flex-end' }}>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            color: ({ palette }) => {
                              if (costSavingTrend?.color === 'disabled') {
                                return palette.text.disabled;
                              }
                              return palette[costSavingTrend?.color]?.main;
                            }
                          }}
                          variant={'subtitle1'}>
                          {costSavingTrend?.percentageChange}
                        </Typography>
                        {costSavingTrend?.arrow}
                      </Stack>
                    )}
                  </Stack>
                )}
                <Typography variant={'body2'}>Last {selectedRange?.display}</Typography>
              </Stack>
            </Stack>
            <Stack sx={{ p: 2 }} direction={'row'} spacing={1} id="potential-eng-hr-saving">
              <RestoreIcon color={'inherit'} />
              <Stack>
                <Typography variant={'body2'}>Potential engine hours saving</Typography>
                {loading ? (
                  <Skeleton height={'3em'} />
                ) : (
                  <Stack direction={'row'} spacing={0.5}>
                    <Typography variant={'h5'} bold>
                      {data.runTimeSaving ? parseInt(data.runTimeSaving) : 0} hours
                    </Typography>
                    {displayArrow(prevData?.runTimeSaving, data?.runTimeSaving) && (
                      <Stack direction={'row'} sx={{ alignItems: 'flex-end' }}>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            color: ({ palette }) => {
                              if (runTimeSavingTrend?.color === 'disabled') {
                                return palette.text.disabled;
                              }
                              return palette[runTimeSavingTrend?.color]?.main;
                            }
                          }}
                          variant={'subtitle1'}>
                          {runTimeSavingTrend?.percentageChange}
                        </Typography>
                        {runTimeSavingTrend?.arrow}
                      </Stack>
                    )}
                  </Stack>
                )}
                <Typography variant={'body2'}>Last {selectedRange?.display}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={5} sx={{ pl: 5 }}>
            <InsightDisplayValue
              title={'Potential Energy Savings'}
              value={valueWithUnit(data?.energySaving || 0, DISPLAY_UNIT.ENERGY)}
              loading={loading}
              subtitle={selectedRange?.display}
              trend={energySavingTrend}
              displayArrow={displayArrow(prevData?.energySaving, data?.energySaving)}
              id={'potential-energy-saving'}
            />
            <InsightDisplayValue
              title={'Potential Fuel savings'}
              value={valueWithUnit(data?.fuelSaving || 0, DISPLAY_UNIT.FUEL)}
              loading={loading}
              subtitle={selectedRange?.display}
              trend={fuelSavingTrend}
              displayArrow={displayArrow(prevData?.fuelSaving, data?.fuelSaving)}
              id={'potential-fuel-saving'}
            />
            <InsightDisplayValue
              title={
                <Typography variant={'body2'}>
                  Potential CO<sub>2</sub>e savings
                </Typography>
              }
              value={valueWithUnit(data?.co2Saving || 0, DISPLAY_UNIT.CO2)}
              loading={loading}
              subtitle={selectedRange?.display}
              trend={co2SavingTrend}
              displayArrow={displayArrow(prevData?.co2Saving, data?.co2Saving)}
              id={'potential-co2-saving'}
            />
            <InsightDisplayValue
              title={
                <Typography variant={'body2'}>
                  Potential NO<sub>x</sub> avoidance
                </Typography>
              }
              value={valueWithUnit(data?.noxSaving || 0, DISPLAY_UNIT.NOX)}
              loading={loading}
              subtitle={selectedRange?.display}
              trend={noxSavingTrend}
              displayArrow={displayArrow(prevData?.noxSaving, data?.energySaving)}
              id={'potential-nox-avoidance'}
            />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

Insights.propTypes = {};
Insights.defaultProps = {};

export default Insights;
