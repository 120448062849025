import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Button, ToolTip, Typography } from '@esgian/esgianui';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_ZONE } from '@constants';
import { toast } from 'react-toastify';
import {
  setTimeZone,
  setCustomUTC,
  getMainPageSlice
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import { useTheme } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';

const DateRangeWellSelector = ({ start, end, selectedDates, onSave, wellsLookup }) => {
  const { theme } = useTheme();
  const { selectedTimeZone } = useTimezone();
  const dispatch = useDispatch();
  const [delayedStart, setDelayedStart] = useState(start);
  const [delayedEnd, setDelayedEnd] = useState(end);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const { rigUTC, timeZone, selectedDateType, customUTC } = useSelector(getMainPageSlice);

  const isTimeZoneMatch = (well) => {
    const dateParts = well.starttime.split(/([+-]\d{2}:\d{2})$/);
    const offset = dateParts[1];
    const possibleTimezones = moment.tz.names().filter((tz) => {
      return moment.tz(well.starttime, tz).format('Z') === offset;
    });
    const currentTimeZone = timeZone !== TIME_ZONE.RIGS_LOCATION ? customUTC?.value : rigUTC;
    const currentUtcOffset = moment.tz(currentTimeZone).utcOffset() / 60;
    const selectedUtcOffset = moment.tz(possibleTimezones[0]).utcOffset() / 60;
    if (currentUtcOffset === selectedUtcOffset) {
      return {
        possibleTimezones,
        match: true,
        offset
      };
    }
    return {
      possibleTimezones,
      match: false,
      offset
    };
  };

  useEffect(() => {
    // Clear any existing timeout when start or end changes to avoid unnecessary renders
    setIsDebouncing(true);
    const delayTimer = setTimeout(() => {
      setDelayedStart(start);
      setDelayedEnd(end);
      setIsDebouncing(false);
    }, 2000);

    // Cleanup function to cancel the timeout if start or end changes again
    return () => clearTimeout(delayTimer);
  }, [start, end]);

  const startDate = useMemo(() => moment(delayedStart).startOf('day'), [delayedStart]);
  const endDate = useMemo(() => moment(delayedEnd).endOf('day'), [delayedEnd]);

  const totalRange = useMemo(
    () => moment(endDate).diff(moment(startDate), 'days'),
    [startDate, endDate]
  );

  const bigDiff = useMemo(() => {
    return moment(endDate).diff(moment(startDate), 'months', true) > 1;
  }, [startDate, endDate]);

  const getWellPosition = (well) => {
    const wellStartDate = moment(well.starttime).startOf('day');
    const startDiff = wellStartDate.diff(moment(startDate), 'days');
    return `${(startDiff / totalRange) * 100}%`;
  };

  const getWellWidth = (well) => {
    const wellStartDate = moment(moment(well.starttime).format(DATE_TIME_FORMAT)).startOf('day');
    const wellEnd = moment(moment(well.endtime).format(DATE_TIME_FORMAT)).endOf('day');
    const wellEndDate = bigDiff ? wellEnd.add(1, 'd') : wellEnd;
    const wellDuration = wellEndDate.diff(wellStartDate, 'days');
    return `${(wellDuration / totalRange) * 100}%`;
  };

  const handleWellClick = (well) => {
    const { possibleTimezones, match, offset } = isTimeZoneMatch(well);
    if (!match) {
      toast.warning(`Timezone changed to UTC ${offset}`);
    }
    const timezoneName = possibleTimezones.length
      ? possibleTimezones[0]
      : 'No matching timezone found';
    dispatch(setTimeZone('CustomTimeZone'));
    dispatch(setCustomUTC({ value: timezoneName, label: `${timezoneName} (UTC${offset})` }));
    onSave({
      startDate: well.starttime,
      endDate: well.endtime,
      name: `${well?.wellname}: ${moment(well.starttime, DATE_FORMAT).format(
        DATE_FORMAT
      )} - ${moment(well.endtime, DATE_FORMAT).format(DATE_FORMAT)}`,
      type: 'well'
    });
  };

  return (
    <Box sx={{ position: 'relative', height: '18px', marginBottom: '4px', overflow: 'hidden' }}>
      {isDebouncing ? (
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: ({ palette }) => palette.background.paper,
            opacity: 0.7,
            zIndex: 1
          }}
        />
      ) : (
        wellsLookup.data.map((well, i) => {
          const selected =
            selectedDateType && isTimeZoneMatch(well).match
              ? moment(well.starttime).isSame(moment(selectedDates[0])) &&
                moment(well.endtime).isSame(moment(selectedDates[1]))
              : false;
          return (
            <ToolTip
              key={i}
              arrow
              placement="top"
              title={
                <Box sx={{ width: '268px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginBottom: 0.5 }}>
                    {well.wellname}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0.5 }}>
                    <Typography variant="body2">Well ID:</Typography>
                    <Typography variant="body2">{well.wellname}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0.5 }}>
                    <Typography variant="body2">Start Date:</Typography>
                    <Typography variant="body2">
                      {moment(well.starttime).tz(selectedTimeZone).format(DATE_FORMAT)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2">End Date:</Typography>
                    <Typography variant="body2">
                      {moment(well.endtime).tz(selectedTimeZone).format(DATE_FORMAT)}
                    </Typography>
                  </Box>
                </Box>
              }>
              <Button
                onClick={() => handleWellClick(well)}
                color={selected ? 'primary' : 'secondary'}
                sx={{
                  mr: 1,
                  background: selected
                    ? '#017CFF'
                    : theme.mode === 'dark'
                    ? 'rgba(196, 196, 196, 0.25)'
                    : 'rgba(123, 123, 123, 0.25)',
                  borderRight: '0.5px solid',
                  borderLeft: '0.5px solid',
                  borderColor: theme.mode === 'dark' ? '#000' : '#fff',
                  position: 'absolute',
                  left: getWellPosition(well),
                  width: `calc(${getWellWidth(well)} - 2px)`,
                  padding: 0,
                  borderRadius: 0,
                  height: 18,
                  fontSize: 10,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                  zIndex: selected ? 2 : 1,
                  py: selected ? '1px' : 0,
                  '&:hover': {
                    zIndex: 3,
                    p: 0
                  }
                }}>
                {well.wellname}
              </Button>
            </ToolTip>
          );
        })
      )}
    </Box>
  );
};

DateRangeWellSelector.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  selectedDates: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  wellsLookup: PropTypes.object.isRequired
};

export default DateRangeWellSelector;
