import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CompareIcon,
  Grid,
  NoResult,
  Slide,
  Stack,
  Typography
} from '@esgian/esgianui';
import { PageActionButtons, RigAnalyticsMenu } from '@components/Menues';
import { APP_PAGES, SUB_SECTIONS } from '@constants';
import {
  AnalyticsSection,
  DrillingActivitiesSection,
  EquipmentSection,
  OverviewSection,
  PowerMgmtSection
} from '@components/Sections';
import { RigSelect } from '@components/Inputs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UnitDisplaySelect from '@components/Inputs/UnitDisplaySelect';
import { useDispatch, useSelector } from 'react-redux';
import { getDisplayUnit, getLookupRigs } from '@store/features';
import { LoadingScreen } from '@components';
import { useUpdateSection } from '@hooks';
import {
  getMainPageSlice,
  setMainPagePeriod,
  setTimeZone,
  setMainPageRig,
  setRigUTC
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import GreenpactLiveStickyHeader from '@pages/GreenpactLivePage/GreenpactLiveStickyHeader';
import tzLookup from 'tz-lookup';
import DateRangeControlPanel from '@components/DateRangeControlPanel';

function GreenpactLivePage() {
  const [initLookup, setInitLookup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateMainPageSection } = useUpdateSection();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lookupRigs = useSelector(getLookupRigs);
  const { selectedSection, selectedRig, startDate, endDate, selectedDateName } =
    useSelector(getMainPageSlice);
  const displayUnit = useSelector(getDisplayUnit);

  useEffect(() => {
    window.scrollTo(0, 0);
    let paramSection = searchParams.has('section') ? parseInt(searchParams.get('section')) : 1;
    if (paramSection !== selectedSection) {
      updateMainPageSection(paramSection);
    }
  }, []);

  useEffect(() => {
    if (!lookupRigs.length) return;
    let rigId = searchParams.get('rig');
    let rig = lookupRigs?.find(({ rigId: curRigId }) => parseInt(rigId) === curRigId);

    if (rig) {
      dispatch(setMainPageRig(rig));
    }
    setInitLookup(true);
  }, [lookupRigs, selectedRig]);

  const { OVERVIEW, ANALYTICS, DRILLING_ACTIVITIES, EQUIPMENT, POWER_MGMT } = SUB_SECTIONS;

  if (selectedSection === null || !initLookup) {
    return <LoadingScreen />;
  }
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <GreenpactLiveStickyHeader />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>
            <Typography
              onClick={() => navigate(APP_PAGES.DASHBOARD)}
              variant={'body2'}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              BACK TO MY FLEET
            </Typography>
            <PageActionButtons />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <RigSelect
                customWidth={602}
                selectedRig={selectedRig}
                onChange={(rig) => {
                  const timezone2 = tzLookup(rig?.latitude, rig?.longitude);
                  dispatch(setTimeZone('RigsLocation'));
                  dispatch(setRigUTC(timezone2));

                  dispatch(setMainPageRig(rig));
                  searchParams.set('rig', rig.rigId);
                  setSearchParams(searchParams);
                }}
              />
              <UnitDisplaySelect />
            </Stack>
            <Button
              onClick={() => navigate(APP_PAGES.BASELINE_COMP)}
              startIcon={<CompareIcon fontSize={'small'} color={'inherit'} />}
              variant={'outlined'}>
              Baseline comparison
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
          <DateRangeControlPanel
            selectedDate={[startDate, endDate]}
            selectedDateName={selectedDateName}
            start={selectedRig.lowerLimit}
            onSave={(period) => dispatch(setMainPagePeriod(period))}
            selectedRig={selectedRig}
          />
        </Grid>

        <Grid item xs={12}>
          <RigAnalyticsMenu />
        </Grid>
        {!selectedRig && (
          <Grid item xs={12}>
            <NoResult header={'No rig selected.'} text={'Please select a rig to view GPR data.'} />
          </Grid>
        )}
        {selectedRig && (
          <Grid item xs={12}>
            <Slide direction="right" in={selectedSection === OVERVIEW} mountOnEnter unmountOnExit>
              <div>
                <OverviewSection />
              </div>
            </Slide>
            <Slide direction="right" in={selectedSection === ANALYTICS} mountOnEnter unmountOnExit>
              <div>
                <AnalyticsSection />
              </div>
            </Slide>
            <Slide
              direction="right"
              in={selectedSection === DRILLING_ACTIVITIES}
              mountOnEnter
              unmountOnExit>
              <div>
                <DrillingActivitiesSection displayUnit={displayUnit} />
              </div>
            </Slide>
            <Slide direction="right" in={selectedSection === EQUIPMENT} mountOnEnter unmountOnExit>
              <div>
                <EquipmentSection />
              </div>
            </Slide>
            <Slide direction="right" in={selectedSection === POWER_MGMT} mountOnEnter unmountOnExit>
              <div>
                <PowerMgmtSection />
              </div>
            </Slide>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

GreenpactLivePage.propTypes = {};

GreenpactLivePage.defaultProps = {};

export default GreenpactLivePage;
