import React, { useState } from 'react';
import { Box, Button, ButtonGroup, Grid, Stack, Typography } from '@esgian/esgianui';
import KeyFigureValueDisplay from '../../components/Display/KeyFigureValueDisplay';
import { Insights, RigsOverviewTable } from '@components/Sections';
import { DashboardOverviewMap } from '@components/Maps';
import { useSelector } from 'react-redux';
import { getLookupRigs, getUser } from '@store/features';

function DashboardPage() {
  const [isMap, setIsMap] = useState(true);
  const user = useSelector(getUser);
  const lookupRigs = useSelector(getLookupRigs);

  const { profile } = user;

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>My Rigs Live</Typography>
            {/*<PageActionButtons />*/}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'h2'} bold>
              {profile?.company}
            </Typography>
            <Stack direction={'row'} spacing={5} sx={{ textAlign: 'center' }}>
              <KeyFigureValueDisplay
                id={'total-rigs'}
                unitValue={'Total Rigs'}
                unitValueVariant={'body2'}
                mainValueVariant={'h3'}
                mainValue={lookupRigs?.length}
                boldMainValue
              />
              <KeyFigureValueDisplay
                id={'tracked-rigs'}
                unitValue={'With GP data'}
                unitValueVariant={'body2'}
                mainValueVariant={'h3'}
                mainValue={[...lookupRigs]?.filter(({ isTracked }) => isTracked)?.length}
                boldMainValue
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Insights />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} sx={{ alignItems: 'center', margin: '0 0 14px 0' }}>
            <Typography sx={{ padding: '0 8px 0 14px', fontSize: '12px' }}>VIEW BY:</Typography>
            <ButtonGroup size={'small'} variant={'contained'}>
              <Button
                sx={{
                  padding: '4px 8px',
                  borderRadius: '4px',
                  color: ({ palette }) =>
                    isMap ? palette.primary.contrastText : palette.secondary.contrastText,
                  backgroundColor: ({ palette }) =>
                    isMap ? palette.primary.main : palette.secondary.main, // Custom color
                  '&:hover': {
                    backgroundColor: ({ palette }) =>
                      isMap ? palette.primary.dark : palette.secondary.dark
                  }
                }}
                onClick={() => setIsMap(true)}
                id={'map-button-select'}>
                <Typography variant={'caption'}>MAP</Typography>
              </Button>
              <Button
                sx={{
                  borderRadius: '4px',
                  padding: '4px 8px',
                  color: ({ palette }) =>
                    !isMap ? palette.primary.contrastText : palette.secondary.contrastText,
                  backgroundColor: ({ palette }) =>
                    !isMap ? palette.primary.main : palette.secondary.main, // Custom color
                  '&:hover': {
                    backgroundColor: ({ palette }) =>
                      !isMap ? palette.primary.dark : palette.secondary.dark
                  }
                }}
                onClick={() => setIsMap(false)}
                id={'list-button-select'}>
                <Typography variant={'caption'}>LIST</Typography>
              </Button>
            </ButtonGroup>
          </Stack>
          {isMap ? <DashboardOverviewMap /> : <RigsOverviewTable />}
        </Grid>
      </Grid>
    </Box>
  );
}

DashboardPage.propTypes = {};

DashboardPage.defaultProps = {};

export default DashboardPage;
