import React from 'react';
import { Box } from '@esgian/esgianui';

function ChartLoading() {
  return (
    <Box sx={{ width: '100%', height: '100%', textAlign: 'center', alignContent: 'center' }}>
      <div className="lds-facebook">
        <Box />
        <Box />
        <Box />
      </div>
    </Box>
  );
}

export default ChartLoading;
