import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DATE_TIMEZONE_FORMAT } from '@constants';
import { Slider } from '@esgian/esgianui';
import { useTimezone } from '@hooks/useTimezone';

const DateRangeSliderControl = ({ start, end, selectedDates, setSelectedDates }) => {
  const { selectedTimeZone } = useTimezone();
  const todayDate = moment().tz(selectedTimeZone).startOf('day');
  const startDate = useMemo(() => moment(start).tz(selectedTimeZone, true).startOf('day'), [start]);
  const endDate = useMemo(() => moment(end).tz(selectedTimeZone).endOf('day'), [end]);
  const getTimestamp = (date) => moment(date).tz(selectedTimeZone).valueOf();

  const marks = useMemo(() => {
    const monthsArray = [];
    let currentMonth = moment(startDate).tz(selectedTimeZone).startOf('month');
    const diff = moment(endDate).diff(moment(startDate), 'months', true);
    if (diff <= 1) {
      let currentDate = moment(startDate).tz(selectedTimeZone);
      while (currentDate.isBefore(endDate)) {
        monthsArray.push({
          value: getTimestamp(currentDate.toDate()),
          label: currentDate.format('YYYY-MM-DD') // Format as week
        });
        currentDate = currentDate.add(1, 'week').startOf('week');
      }
    } else {
      while (currentMonth.isBefore(endDate)) {
        monthsArray.push({
          value: getTimestamp(currentMonth.toDate()),
          label: currentMonth.format('YYYY-MM')
        });
        currentMonth = currentMonth.add(1, 'month').startOf('month');
      }
    }

    const lastMonthValue = getTimestamp(monthsArray[monthsArray.length - 1].value);
    const daysDifference = moment(todayDate).tz(selectedTimeZone).diff(lastMonthValue, 'days');

    if (daysDifference <= 15) {
      monthsArray[monthsArray.length - 1] = {
        value: getTimestamp(todayDate),
        label: 'Today'
      };
    } else {
      monthsArray.push({
        value: getTimestamp(todayDate),
        label: 'Today'
      });
    }

    return monthsArray;
  }, [startDate, endDate]);

  const bigDiff = useMemo(() => {
    return moment(endDate).diff(moment(startDate), 'months', true) > 1;
  }, [startDate, endDate]);

  const [sliderValue, setSliderValue] = useState(() => [
    getTimestamp(moment(selectedDates[0]).tz(selectedTimeZone).startOf('day')),
    bigDiff
      ? getTimestamp(moment(selectedDates[1]).tz(selectedTimeZone).endOf('day'))
      : getTimestamp(moment(selectedDates[1]).tz(selectedTimeZone).startOf('day'))
  ]);

  useEffect(() => {
    setSliderValue([
      getTimestamp(moment(selectedDates[0]).tz(selectedTimeZone).startOf('day')),
      bigDiff
        ? getTimestamp(moment(selectedDates[1]).tz(selectedTimeZone).endOf('day'))
        : getTimestamp(moment(selectedDates[1]).tz(selectedTimeZone).startOf('day'))
    ]);
  }, [selectedDates]);

  const handleSliderChange = (_, newValue) => {
    setSliderValue(newValue);
  };

  const handleSliderChangeCommitted = (_, newValue) => {
    const startDate = moment(start).tz(selectedTimeZone).startOf('day');
    const endDate = moment(end).tz(selectedTimeZone).endOf('day');
    const [selectedStart, selectedEnd] = selectedDates.map((date) =>
      moment(date).tz(selectedTimeZone).startOf('day')
    );
    const [newValueStart, newValueEnd] = newValue.map((val) =>
      moment(val).tz(selectedTimeZone).startOf('day')
    );

    // Define the new selected dates
    let newStart = selectedStart;
    let newEnd = selectedEnd;
    if (selectedStart.isAfter(endDate, 'day') || selectedStart.isAfter(newValueStart, 'day')) {
      newStart = newValueStart.clone().startOf('day');
    } else if (selectedEnd.isAfter(endDate, 'day') && !selectedStart.isSame(newValueStart, 'day')) {
      newEnd = newValueStart.clone().endOf('day');
    } else if (selectedEnd.isBefore(startDate, 'day')) {
      newEnd = newValueEnd.clone().endOf('day');
    } else if (newValueEnd.isAfter(selectedEnd, 'day')) {
      newEnd = newValueEnd.clone().endOf('day');
    } else if (selectedStart.isBefore(startDate, 'day')) {
      newStart = newValueEnd.clone().startOf('day');
    } else {
      newStart = newValueStart.clone().startOf('day');
      newEnd = newValueEnd.clone().endOf('day');
    }

    // Update state only if there's a change
    if (!newStart.isSame(selectedStart, 'day') || !newEnd.isSame(selectedEnd, 'day')) {
      setSelectedDates([
        newStart.startOf('day').format(DATE_TIMEZONE_FORMAT),
        newEnd.endOf('day').format(DATE_TIMEZONE_FORMAT)
      ]);
    }
  };

  return (
    <Slider
      disableSwap
      value={sliderValue}
      onChange={handleSliderChange}
      onChangeCommitted={handleSliderChangeCommitted}
      valueLabelDisplay="auto"
      valueLabelFormat={(timestamp) => moment(timestamp).tz(selectedTimeZone).format('YYYY-MM-DD')}
      min={getTimestamp(startDate)}
      max={getTimestamp(endDate)}
      step={24 * 60 * 60 * 1000}
      marks={marks}
      sx={{
        position: 'absolute',
        bottom: 27,
        zIndex: 2,
        padding: '0',
        m: '0',
        width: '100% !important',
        '& .MuiSlider-markLabel': {
          transform: 'rotate(0deg)',
          whiteSpace: 'nowrap',
          marginLeft: '-20px',
          top: '10px',
          fontSize: 10,
          color: ({ palette }) => palette.text.disabled
        },
        '& .MuiSlider-markLabel[data-index="0"]': {
          marginLeft: '1px !important'
        },
        '& .MuiSlider-valueLabelOpen': {
          borderRadius: '4px',
          width: '76px',
          height: '24px',
          rotate: '-45deg',
          transform: 'translate(-6px, 6px)!important'
        },
        '& .MuiSlider-valueLabelCircle': {
          rotate: '-45deg'
        },
        '& .MuiSlider-track': {
          color: '#88C7FF'
        },
        '& .MuiSlider-thumb': {
          color: '#0E9EFF',
          border: 'none'
        },
        '& .MuiSlider-rail': {
          color: '#555555'
        }
      }}
    />
  );
};

DateRangeSliderControl.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  selectedDates: PropTypes.array.isRequired,
  setSelectedDates: PropTypes.func.isRequired
};

export default DateRangeSliderControl;
