import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarChart from '../SimpleBarChart';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { useDisplayUnit } from '@hooks';

function DrillingEquipmentChart({ categories, loading, series, primaryXAxisLabel }) {
  const { displayUnit } = useDisplayUnit();

  return (
    <SimpleBarChart
      primaryTitleOffsetY={330}
      primaryXAxisLabel={primaryXAxisLabel}
      loading={loading}
      vertical
      categories={categories}
      series={series}
      customTooltipContent={(series, dataPointIndex) => (
        <KeyValueTooltip
          displayUnit={displayUnit}
          label={categories[dataPointIndex]}
          value={series[0][dataPointIndex]}
          unit={primaryXAxisLabel}
        />
      )}
      id="drilling-equipment-chart"
    />
  );
}
DrillingEquipmentChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  primaryXAxisLabel: PropTypes.string
};

DrillingEquipmentChart.defaultProps = {
  series: [],
  categories: [],
  loading: false,
  primaryXAxisLabel: ''
};

export default DrillingEquipmentChart;
