import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import moment from 'moment-timezone';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { getValue } from '@helpers';

function OperationsPeriodicOverviewChart({ unitTitle, loading, categories, series }) {
  const [logInfo, setLogInfo] = useState(null);
  const chartRef = useRef();

  const handleLogClick = useCallback(
    (date) => {
      setLogInfo({ startDate: moment.utc(parseInt(date)), unit: 'day' });
    },
    [categories]
  );
  let maxStackedValue = 0;

  series[0]?.data.forEach((point, index) => {
    const stackedValue = getValue(point.y) + getValue(series[1].data[index].y);
    if (stackedValue > maxStackedValue) {
      maxStackedValue = stackedValue;
    }
  });

  // MONDAY NOTES FIXED DATES FROM 1600 and also the max does not work.
  return (
    <>
      <CanvasTimeSeriesChart
        disableHoverLinePlugin
        enableBarHover
        includeTooltipSum
        maxY={maxStackedValue * 1.2}
        stackedBar
        chartRef={chartRef}
        id={'operation-periodic-overview-chart'}
        handleLogClick={handleLogClick}
        height={'360px'}
        unit={unitTitle}
        series={series}
        loading={loading}
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </>
  );
}

OperationsPeriodicOverviewChart.propTypes = {
  loading: PropTypes.bool,
  unitTitle: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.object)
};

OperationsPeriodicOverviewChart.defaultProps = {
  loading: false,
  unitTitle: '',
  categories: [],
  series: []
};

export default OperationsPeriodicOverviewChart;
