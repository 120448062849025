import { DISPLAY_UNIT } from '@constants';

import { useCallback } from 'react';
import { useDisplayUnit } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getUnixTimeStamp, getValue } from '@helpers';

export const useSeriesBuilder = () => {
  const { getUnitTitle, convertUnitValue } = useDisplayUnit();
  const displayUnit = useSelector(getDisplayUnit);

  const getMaxOfStackedSeries = useCallback((data) => {
    let max = 0;
    for (let i = 0; i < data[0].length; i++) {
      let sum = 0;
      for (let j = 0; j < data.length; j++) {
        sum += data[j][i] ? parseFloat(data[j][i]) : 0;
      }
      max = sum > max ? sum : max;
    }
    return max;
  }, []);

  const getSeriesData = useCallback((data, unit) => {
    let max = 0;

    let defaultArray = [];
    let convertedArray = [];

    Object.values(data)?.forEach((val) => {
      const v = getValue(val, false);
      max = max < v ? v : max;
      defaultArray.push(v);
      convertedArray.push(convertUnitValue(v, unit));
    });
    return { max: max, defaultArray: defaultArray, convertedArray: convertedArray };
  }, []);

  const getCanvasTimeSeriesData = useCallback((data, unit) => {
    let max = 0;

    let defaultArray = [];
    let convertedArray = [];

    Object.keys(data)?.forEach((key) => {
      const value = getValue(data[key], false);
      max = max < value ? value : max;
      defaultArray.push({ x: getUnixTimeStamp(key), y: value });
      convertedArray.push({ x: getUnixTimeStamp(key), y: convertUnitValue(value, unit, false) });
    });
    return { max: max, defaultArray: defaultArray, convertedArray: convertedArray };
  }, []);

  const getEquipmentDataFields = useCallback((data, displayUnit) => {
    let mp = data[`MP${displayUnit}`] || {};
    let hpu = data[`HPU${displayUnit}`] || {};
    let dw = data[`DW${displayUnit}`] || {};
    let td = data[`TD${displayUnit}`] || {};
    if (displayUnit === DISPLAY_UNIT.ENERGY) {
      dw = data.DWEnergyCons;
    }
    return {
      mp: mp,
      hpu: hpu,
      dw: dw,
      td: td
    };
  }, []);

  return {
    getSeriesData,
    getEquipmentDataFields,
    getCanvasTimeSeriesData,
    getMaxOfStackedSeries
  };
};
