import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import { Bar } from 'react-chartjs-2';
import { fetchOverviewKeyFiguresData } from '@api/Rig';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { API_ROLLUP, DATE_FORMAT } from '@constants';
import Wave from 'react-wavify';
import moment from 'moment-timezone';

const fillMissingDates = (startDate, endDate, dataObject) => {
  const filledData = { ...dataObject };
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(moment(endDate), 'day')) {
    const dateKey = currentDate.format('YYYY-MM-DDT00:00:00+00:00');

    if (!filledData.hasOwnProperty(dateKey)) {
      filledData[dateKey] = '0';
    }

    currentDate = currentDate.add(1, 'day');
  }

  return filledData;
};

const TimeRangeBarChart = ({
  start,
  end,
  earliestStart,
  latestEnd,
  selectedDates,
  setCurrentDisplayedDates,
  selectedRig
}) => {
  const displayUnit = useSelector(getDisplayUnit);
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    const loadOverviewData = async () => {
      try {
        const data = await fetchOverviewKeyFiguresData(
          abortController.signal,
          { selectedRig, startDate: earliestStart, endDate: latestEnd },
          API_ROLLUP.DAY
        );
        setOverviewData(data);
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    };

    loadOverviewData();

    return () => {
      abortController.abort();
    };
  }, [selectedRig, earliestStart, latestEnd]);

  const currentOverviewData = useMemo(() => {
    const data = overviewData?.clarifyData?.[`Rig${displayUnit}`];
    if (!data) {
      return [];
    }
    const filteredData = Object.keys(data)
      .filter((date) => {
        const currentDate = moment(date);
        return currentDate.isBetween(
          moment(earliestStart),
          moment(latestEnd).endOf('day'),
          null,
          '[]'
        );
      })
      .reduce((result, date) => {
        result[date] = data[date];
        return result;
      }, {});

    return filteredData;
  }, [overviewData, earliestStart, latestEnd]);

  const displayUnitData = useMemo(
    () => fillMissingDates(earliestStart, latestEnd, currentOverviewData),
    [currentOverviewData, displayUnit]
  );

  const labels = useMemo(() => {
    if (displayUnitData) {
      return Object.keys(displayUnitData).map((d) => moment(d).format(DATE_FORMAT));
    }
    return [];
  }, [displayUnitData]);

  const values = useMemo(() => {
    if (displayUnitData) {
      return Object.values(displayUnitData).map((i) => parseInt(i));
    }
  }, [displayUnitData]);

  const backgroundColors = useMemo(() => {
    const startDate = moment.utc(selectedDates[0]).startOf('day');
    const endDate = moment.utc(selectedDates[1]).endOf('day');
    return labels.map((label) => {
      const currentDate = moment.utc(label).startOf('day');
      return currentDate.isBetween(startDate, endDate, null, '[]') ? '#0E9EFF' : '#7B7B7B';
    });
  }, [labels, selectedDates]);

  const data = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          data: values,
          backgroundColor: backgroundColors,
          borderColor: 'transparent',
          borderWidth: 0
        }
      ]
    };
  }, [labels, values, backgroundColors]);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSetCurrentDisplayedDates = useCallback(debounce(setCurrentDisplayedDates, 20), []);

  const config = useMemo(
    () => ({
      type: 'bar',
      data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
          zoom: {
            zoom: {
              drag: {
                enabled: false
              },
              pinch: {
                enabled: true
              },
              wheel: {
                enabled: true,
                modifierKey: 'ctrl'
              },
              mode: 'x',
              onZoom: ({ chart }) => {
                const xAxis = chart.scales.x;
                setCurrentDisplayedDates([
                  moment(earliestStart).add(xAxis.min, 'day').startOf('day'),
                  moment(earliestStart).add(xAxis.max, 'day').endOf('day')
                ]);
              }
            },
            pan: {
              enabled: true,
              speed: 1,
              mode: 'x',
              onPan: ({ chart }) => {
                const xAxis = chart.scales.x;
                debouncedSetCurrentDisplayedDates([
                  moment(earliestStart).add(xAxis.min, 'day').startOf('day'),
                  moment(earliestStart).add(xAxis.max, 'day').endOf('day')
                ]);
              }
            },
            limits: {
              x: {
                minRange: 180
              }
            }
          }
        },
        scales: {
          x: {
            min: moment(start).diff(moment(earliestStart), 'days'),
            max: moment(end).diff(moment(earliestStart), 'days') + 1,
            offset: false,
            grid: { display: false },
            ticks: { display: false }
          },
          y: {
            display: false
          }
        },
        layout: {
          padding: 0
        },
        datasets: {
          bar: {
            barThickness: 'flex',
            maxBarThickness: 6
          }
        }
      }
    }),
    [data, start]
  );

  return (
    <Box sx={{ width: '100%', position: 'absolute', top: 20 }}>
      {loading ? (
        <Wave
          fill="#7B7B7B"
          paused={false}
          style={{ height: '18px' }}
          options={{
            height: 5,
            amplitude: 5,
            speed: 0.4,
            points: 5
          }}
        />
      ) : (
        <Bar height={20} data={config.data} options={config.options} />
      )}
    </Box>
  );
};

TimeRangeBarChart.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  earliestStart: PropTypes.string.isRequired,
  latestEnd: PropTypes.string,
  selectedDates: PropTypes.array.isRequired,
  setCurrentDisplayedDates: PropTypes.func.isRequired,
  selectedRig: PropTypes.object.isRequired
};

export default TimeRangeBarChart;
