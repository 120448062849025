import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Stack,
  Typography,
  InputLabel,
  Select
} from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getLookupRigs } from '@store/features';
import { useTheme } from '@hooks';

const sortRigs = (a, b) => {
  if (a.rigName < b.rigName) {
    return -1;
  }
  if (a.rigName > b.rigName) {
    return 1;
  }
  return 0;
};

function RigSelect({
  required,
  disabled,
  selectedRig,
  onChange,
  id,
  showTitle,
  helpertext,
  error,
  customWidth
}) {
  const [options, setOptions] = useState([]);
  const lookupRigs = useSelector(getLookupRigs);
  const { customScrollbar } = useTheme();

  useEffect(() => {
    if (!lookupRigs?.length) return;
    setOptions([...lookupRigs]?.sort(sortRigs));
  }, [lookupRigs]);

  const handleChange = useCallback(
    ({ target }) => {
      let rig = lookupRigs?.find(({ rigId }) => rigId === parseInt(target.value));
      if (!rig) return;
      onChange(rig);
    },
    [lookupRigs]
  );

  const value = useMemo(() => {
    if (!options.length) return '';

    if (typeof selectedRig === 'object') {
      return selectedRig?.rigId ?? '';
    }
    return parseInt(selectedRig) || '';
  }, [options, selectedRig]);

  return (
    <Stack id={id} sx={{ width: customWidth }}>
      {showTitle && <Typography variant={'body2'}>Rig</Typography>}
      <FormControl
        sx={{
          minWidth: 224,
          height: '47px'
        }}
        size={'medium'}>
        <InputLabel id="rig-label">
          {!options.length ? (
            <CircularProgress size={25} />
          ) : showTitle ? (
            ''
          ) : (
            `Rig${required ? '*' : ''}`
          )}
        </InputLabel>
        <Select
          helpertext={helpertext}
          labelId="rig-label"
          id="rig-select"
          value={value}
          disabled={disabled}
          error={error}
          label={
            !options.length ? (
              <CircularProgress size={25} />
            ) : showTitle ? (
              ''
            ) : (
              `Rig${required ? '*' : ''}`
            )
          }
          onChange={handleChange}
          sx={{
            height: '47px',
            display: 'flex',
            alignItems: 'center'
          }}
          MenuProps={{
            PaperProps: {
              sx: { ...customScrollbar, maxHeight: '40vh' }
            }
          }}>
          {options.map(({ isTracked, rigName, rigId }) => (
            <MenuItem
              key={`rig-select-${rigId}`}
              value={rigId}
              disabled={!isTracked}
              sx={{
                '&:hover': {
                  color: ({ palette }) => palette.primary.main
                }
              }}>
              {rigName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

RigSelect.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showTitle: PropTypes.bool,
  selectedRig: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  helpertext: PropTypes.string,
  error: PropTypes.bool,
  customWidth: PropTypes.any
};

RigSelect.defaultProps = {
  showTitle: false,
  required: false,
  onChange: undefined,
  selectedRig: null,
  helpertext: null,
  disabled: false,
  error: false,
  id: 'rig-select',
  customWidth: 'auto'
};

export default RigSelect;
