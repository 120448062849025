export const barHoverPlugin = {
  id: 'barHoverBackground',
  beforeDatasetsDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { top, width, height },
      scales: { x }
    } = chart;

    ctx.save();

    const hoverValue = chart.$barHoverPluginHoverValue;

    if (hoverValue !== undefined) {
      const segment = width / (x.max + 1);
      ctx.fillStyle = options.backgroundColor;
      ctx.fillRect(x.getPixelForValue(hoverValue) - segment / 2, top, segment, height);
    }
  },
  afterEvent(chart, args) {
    const {
      scales: { x }
    } = chart;

    let hoverValue;

    if (args.inChartArea) {
      hoverValue = x.getValueForPixel(args.event.x);
    }

    // Update hover state only if it changes
    if (hoverValue !== chart.$barHoverPluginHoverValue) {
      chart.$barHoverPluginHoverValue = hoverValue;
      chart.update('none');
    }
  },
  install(chart) {
    const canvas = chart.canvas;

    const handleMouseMove = (event) => {
      const bounds = canvas.getBoundingClientRect();
      const isMouseInside =
        event.clientX >= bounds.left &&
        event.clientX <= bounds.right &&
        event.clientY >= bounds.top &&
        event.clientY <= bounds.bottom;

      if (!isMouseInside && chart.$barHoverPluginHoverValue !== undefined) {
        // Clear hover effect when mouse leaves the chart bounds
        chart.$barHoverPluginHoverValue = undefined;
        chart.update('none');
      }
    };

    // Attach a global mousemove listener
    document.addEventListener('mousemove', handleMouseMove);

    // Cleanup the listener when the chart is destroyed
    chart.$destroyHoverListener = () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  },
  beforeDestroy(chart) {
    // Remove the global listener during chart destruction
    if (chart.$destroyHoverListener) {
      chart.$destroyHoverListener();
      chart.$destroyHoverListener = null;
    }
  }
};
