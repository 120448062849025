import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton, Stack, Typography } from '@esgian/esgianui';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { DISPLAY_UNIT, WARNINGS, WARNINGS_TYPE } from '@constants';
import { useDisplayUnit, useTheme } from '@hooks';

function FocusAreaSummary({ warningSummary, loading }) {
  const {
    theme: { palette: { text: { primary } } = {} }
  } = useTheme();
  const { valueWithUnit } = useDisplayUnit();

  return (
    <Stack spacing={1}>
      <TextWithTooltipIcon
        tooltipText={
          'Accumulated fuel saving potential for all the focus areas in the selected time period.'
        }
        label={<Typography bold>Focus area summary</Typography>}
      />
      <Stack id={'focus-area-summary'}>
        {Object.keys(WARNINGS_TYPE)
          .sort((a, b) => warningSummary[b] - warningSummary[a]) // Sort keys based on values in descending order
          .map((key, i) => (
            <Stack justifyContent={'space-between'} key={`${i}-focus-area`} direction={'row'}>
              <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ ml: 1.5 }}>
                <Box
                  sx={{
                    marginBottom: '2px',
                    height: '5px',
                    width: '5px',
                    borderRadius: '50%',
                    backgroundColor: primary
                  }}
                />
                <Typography variant={'body2'}>
                  {loading ? <Skeleton width={'10em'} /> : `${WARNINGS[key]}`}
                </Typography>
              </Stack>
              <Typography variant={'body2'}>
                {loading ? (
                  <Skeleton width={'10em'} />
                ) : (
                  `${valueWithUnit(warningSummary[key], DISPLAY_UNIT.FUEL)}`
                )}
              </Typography>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}

FocusAreaSummary.propTypes = {
  warningSummary: PropTypes.object,
  loading: PropTypes.bool
};

FocusAreaSummary.defaultProps = {
  warningSummary: {},
  loading: false
};

export default FocusAreaSummary;
