import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { SimpleBarChart } from '@components/Charts';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { DISPLAY_UNIT, EQUIPMENT_TYPES } from '@constants';
import { TextWithTooltipIcon } from '@components';
import { getValue, parseNumberToSignificant } from '@helpers';
import { useDisplayUnit } from '@hooks';

// TODO Use same component for both overview and equipment section
function TotalForPeriod({ equipmentData, loading }) {
  const { getUnitTitle, displayUnit, convertUnitValue } = useDisplayUnit();

  const {
    series = [],
    unit = '',
    categories = []
  } = useMemo(() => {
    if (!equipmentData) return {};
    const { clarifyData } = equipmentData;
    let max = 0;
    let tempCat = [];
    let data = EQUIPMENT_TYPES.map(({ key, name }) => {
      let data = clarifyData[`${key}${displayUnit}`];
      if (!data) return null;
      tempCat.push(name);
      let tot = Object.values(data).reduce((a, b) => a + getValue(b), 0);
      max = max > tot ? max : tot;
      return tot;
    }).filter((val) => val !== null);

    const { title, converted } = getUnitTitle(max);
    data = data.map((d) =>
      converted ? convertUnitValue(d, displayUnit) : parseNumberToSignificant(d)
    );
    return {
      series: [{ name: 'Equipment consumed energy', data: data }],
      unit: title,
      categories: tempCat
    };
  }, [displayUnit, equipmentData]);

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Total equipment CO2e emissions in period';
      case DISPLAY_UNIT.NOX:
        return 'Total equipment NOx emissions in period';
      case DISPLAY_UNIT.FUEL:
        return 'Total equipment fuel consumption in period';
      case DISPLAY_UNIT.ENERGY:
        return 'Total equipment energy consumption in period';
    }
  }, [displayUnit]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={1}>
        <TextWithTooltipIcon
          tooltipText={`Total consumption or emissions for each equipment in the selected time period.`}
          label={<Typography variant={'h6'}>Equipment summary</Typography>}
        />
        <Typography variant="body2">{getSubTitle}</Typography>
        <SimpleBarChart
          primaryTitleOffsetX={unit.length >= 3 ? 0 : 10}
          height={360}
          primaryXAxisLabel={unit}
          primaryTitleOffsetY={0}
          loading={loading}
          categories={categories}
          series={series}
          customTooltipContent={(series, dataPointIndex) => (
            <KeyValueTooltip
              displayUnit={displayUnit}
              label={categories[dataPointIndex]}
              value={series[0][dataPointIndex]}
              unit={unit}
            />
          )}
        />
      </Stack>
    </Paper>
  );
}

TotalForPeriod.propTypes = {
  equipmentData: PropTypes.object,
  loading: PropTypes.bool
};

TotalForPeriod.defaultProps = {
  equipmentData: null,
  loading: false
};

export default TotalForPeriod;
