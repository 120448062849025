import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import RangeButton from '@components/Inputs/PeriodRangeButtons/RangeButton';
import { DISPLAY_PERIOD } from '@constants';

function PeriodRangeButtons({ value, onClick, options, insights }) {
  return (
    <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
      <Typography variant={'body2'} sx={{ pr: 1 }}>
        Latest:
      </Typography>
      {options.includes(DISPLAY_PERIOD.LAST_DAY) && (
        <RangeButton
          selectedValue={value}
          onClick={onClick}
          value={{ index: DISPLAY_PERIOD.LAST_DAY, unit: 'days', number: 1, display: '24 hours' }}
          id={'filter-one-day'}>
          1d
        </RangeButton>
      )}
      {options.includes(DISPLAY_PERIOD.LAST_WEEK) && (
        <RangeButton
          selectedValue={value}
          onClick={onClick}
          value={{ index: DISPLAY_PERIOD.LAST_WEEK, unit: 'days', number: 7, display: '7 days' }}
          id={'filter-seven-days'}>
          7d
        </RangeButton>
      )}
      {options.includes(DISPLAY_PERIOD.LAST_MONTH) && (
        <RangeButton
          selectedValue={value}
          onClick={onClick}
          value={{
            index: DISPLAY_PERIOD.LAST_MONTH,
            unit: insights ? 'days' : 'month',
            number: insights ? 30 : 1,
            display: insights ? '30 days' : 'month'
          }}
          id={'filter-one-month'}>
          {insights ? '30d' : '1M'}
        </RangeButton>
      )}
      {options.includes(DISPLAY_PERIOD.LAST_YEAR) && (
        <RangeButton
          selectedValue={value}
          onClick={onClick}
          value={{
            index: DISPLAY_PERIOD.LAST_YEAR,
            unit: insights ? 'days' : 'year',
            number: insights ? 90 : 1,
            display: insights ? '90 days' : 'year'
          }}
          id={'filter-ninety-days'}>
          {insights ? '90d' : '1Y'}
        </RangeButton>
      )}
      {options.includes(DISPLAY_PERIOD.ALL_TIME) && (
        <RangeButton
          selectedValue={value}
          onClick={onClick}
          value={{ index: DISPLAY_PERIOD.ALL_TIME, unit: 'month', number: 1, display: 'month' }}>
          All time
        </RangeButton>
      )}
    </Stack>
  );
}

PeriodRangeButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  insights: PropTypes.bool
};

PeriodRangeButtons.defaultProps = {
  options: Object.values(DISPLAY_PERIOD),
  insights: false
};

export default PeriodRangeButtons;
