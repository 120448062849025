import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@esgian/esgianui';
import moment from 'moment/moment';
import { UTC_TIMESTAMP } from '@constants';
import { getUnixTimeStamp, getValue } from '@helpers';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { useTheme } from '@hooks';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';

function EnginePercentageLoadChart({ loading, powerMgmtData, setSelectedDate, selectedDate }) {
  const [logInfo, setLogInfo] = useState(null);
  const mainRef = useRef();
  const secRef = useRef();
  const { theme } = useTheme();

  const {
    palette: {
      charts: { primaryChartColor, secondaryChartColor }
    }
  } = theme;

  const { mainSeries = [], secondarySeries = [] } = useMemo(() => {
    if (!powerMgmtData) return {};
    const {
      clarifyData: { EngOn = {}, EngLavg = {} }
    } = powerMgmtData;

    let categories = Object.keys(EngLavg || {})?.map((val) => {
      return getUnixTimeStamp(val);
    });

    let engOnlineSeries = {
      label: 'Engine online',
      type: 'bar',
      backgroundColor: secondaryChartColor,
      borderSkipped: true,
      data: Object.values(EngOn || {})?.map((val, i) => {
        return { x: categories[i], y: getValue(val)?.toFixed(2) };
      }),
      disableLegend: true,
      subHeader: 'Daily average number of engines online'
    };

    return {
      mainSeries: [
        {
          label: 'Load %',
          type: 'bar',
          backgroundColor: primaryChartColor,
          borderSkipped: true,
          data: Object.values(EngLavg || {})?.map((val, i) => {
            return { x: categories[i], y: parseInt(getValue(val)) };
          }),
          disableLegend: true
        },
        { ...engOnlineSeries, unit: '', hidden: true }
      ],
      secondarySeries: [
        {
          ...engOnlineSeries
        }
      ]
    };
  }, [powerMgmtData, theme]);

  const handleColumnClick = useCallback(
    (val) => {
      const { index } = val;
      const timestamp = mainSeries[0].data[index].x;
      // let index = mainSeries[0].data.findIndex(({ x }) => x === parseInt(date));
      //
      setSelectedDate({
        date: moment.utc(parseInt(timestamp)).format(UTC_TIMESTAMP),
        index: index,
        isLast: index === mainSeries[0].data?.length - 1
      });
    },
    [mainSeries]
  );

  const highlightPeriod = useMemo(() => {
    if (!selectedDate)
      return {
        startIndex: 0,
        endIndex: 0
      };

    return {
      startIndex: selectedDate.index,
      endIndex: selectedDate.index
    };
  }, [selectedDate]);
  return (
    <Stack spacing={2}>
      <CanvasTimeSeriesChart
        highlightPeriod={highlightPeriod}
        disableHoverLinePlugin
        enableBarHover
        unit={'%'}
        maxY={100}
        handlePointClick={handleColumnClick}
        handleLogClick={(timestamp) => {
          setLogInfo({
            startDate: moment.utc(parseInt(timestamp)).format(UTC_TIMESTAMP),
            unit: 'day'
          });
        }}
        height={'325px'}
        loading={loading}
        series={mainSeries}
        chartRef={mainRef}
        syncRefs={[secRef]}
      />
      <CanvasTimeSeriesChart
        highlightPeriod={highlightPeriod}
        disableHoverLinePlugin
        enableBarHover
        unit={''}
        height={'180px'}
        handlePointClick={handleColumnClick}
        hideCurrentTooltip
        loading={loading}
        series={secondarySeries}
        maxY={powerMgmtData?.numOfEngines}
        calculateYMax={false}
        syncRefs={[mainRef]}
        chartRef={secRef}
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </Stack>
  );
}

EnginePercentageLoadChart.propTypes = {
  setSelectedDate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  powerMgmtData: PropTypes.object,
  selectedDate: PropTypes.object
};

EnginePercentageLoadChart.defaultProps = {
  selectedDate: {},
  loading: false,
  powerMgmtData: null
};

export default EnginePercentageLoadChart;
