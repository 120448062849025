import React, { useEffect, useMemo } from 'react';
import { ArrowBackIcon, Box, Grid, Stack, Typography } from '@esgian/esgianui';
import { APP_PAGES } from '@constants';
import { BaselineCompActionButtons } from '@components/Menues';
import { useNavigate } from 'react-router-dom';
import { ArrowBackButton } from '@components';
import { RigSelect } from '@components/Inputs';
import DateRangeDropdown from '@components/DateRangeDropdown';
import {
  getMainPageRig,
  getMainPageSelectedSection,
  getMainPageSlice,
  setMainPagePeriod,
  setMainPageRig
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import {
  getBaselineComparisonSlice,
  setBaselineViewSettings
} from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { useDispatch, useSelector } from 'react-redux';
import UnitDisplaySelect from '@components/Inputs/UnitDisplaySelect';
import BaselineInput from '@components/Inputs/BaselineInput';
import { BaselineComparisonSection } from '@components/Sections';
import { getBaselineViewSettings } from '@api/Baselines';
import { getSideMenuOpen, getUser } from '@store/features';
import BaselineCompStickyHeader from '@pages/BaselineComparisonPage/BaselineCompStickyHeader';
import { useQuery } from '@tanstack/react-query';

function BaselineComparisonPage() {
  const navigate = useNavigate();
  const { selectedRig, startDate, endDate, selectedDateName } = useSelector(getMainPageSlice);
  const mainRig = useSelector(getMainPageRig);
  const sideMenuOpen = useSelector(getSideMenuOpen);
  const mainSection = useSelector(getMainPageSelectedSection);
  const { selectedBaseline, ignoreDefaults, hasApiSettings } = useSelector(
    getBaselineComparisonSlice
  );
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const backUri = useMemo(() => {
    let uri = APP_PAGES.RIG_PAGE;
    if (mainRig) {
      uri += `?rig=${mainRig.rigId}&section=${mainSection}`;
    }
    return uri;
  }, [mainRig]);
  const userSettingsQuery = useQuery({
    queryKey: ['baseline', 'view', 'settings', { user: user, hasApiSettings: hasApiSettings }],
    enabled: !!user,
    placeholderData: null,
    queryFn: ({ signal }) => {
      return getBaselineViewSettings(signal, { token: user.token, userId: user.id })
        .then((result) => {
          return result;
        })
        .catch(() => null);
    }
  });

  useEffect(() => {
    const { data } = userSettingsQuery;
    if (data) {
      dispatch(
        setBaselineViewSettings({
          ignoreDefaults: ignoreDefaults,
          defaultDataType: data.defaultDataType,
          defaultChartType: data.defaultChartType,
          graphDisplays: [...data.baselineGraphDisplay].sort((a, b) => a.rank - b.rank)
        })
      );
    }
  }, [userSettingsQuery.data]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: sideMenuOpen ? 'calc(100vw - 300px)' : 'calc(100vw - 100px)'
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>
            <Stack direction={'row'} spacing={2}>
              <ArrowBackButton onClick={() => navigate(backUri)}>
                <ArrowBackIcon color={'inherit'} fontSize={'small'} />
              </ArrowBackButton>
              <Typography variant={'h6'}>Baseline comparison</Typography>
            </Stack>
            <BaselineCompActionButtons />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            flexWrap={'wrap'}
            sx={{ rowGap: '10px' }}>
            <RigSelect
              selectedRig={selectedRig}
              onChange={(period) => dispatch(setMainPageRig(period))}
            />
            <DateRangeDropdown
              startDate={startDate}
              endDate={endDate}
              selectedDateName={selectedDateName}
              onSave={(period) => dispatch(setMainPagePeriod(period))}
            />
            <Typography variant={'subtitle1'} bold>
              Compare to
            </Typography>
            <BaselineInput value={selectedBaseline} />
            <UnitDisplaySelect />
          </Stack>
        </Grid>
        <BaselineCompStickyHeader backUri={backUri} />
        <Grid item xs={12}>
          <BaselineComparisonSection />
        </Grid>
      </Grid>
    </Box>
  );
}

BaselineComparisonPage.propTypes = {};

BaselineComparisonPage.defaultProps = {};

export default BaselineComparisonPage;
