import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks';

function LegendValue({ handleHover, onClick, inactive, textVariant, color, value, sx, isLine }) {
  const { theme } = useTheme();
  const background = useMemo(() => {
    if (!color) return;
    if (typeof color === 'string') {
      return color;
    }

    const {
      palette: { charts }
    } = theme;
    if (color.isSingle) {
      return charts[color.name];
    }

    return charts[color.name][color.index];
  }, [color, theme]);

  const inactiveStyle = inactive
    ? {
        opacity: 0.8,
        filter: 'brightness(0.8)'
      }
    : {};

  const legendSx = {
    ...sx,
    cursor: onClick ? 'pointer' : 'default',
    ...inactiveStyle
  };

  return (
    <Stack
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      onClick={onClick}
      sx={legendSx}
      direction={'row'}
      alignItems={'center'}
      spacing={0.5}>
      <div
        style={{
          height: isLine ? '3px' : '8px',
          width: '8px',
          background: background
        }}
      />
      <Typography sx={{ lineHeight: '16px' }} variant={textVariant}>
        {value}
      </Typography>
    </Stack>
  );
}

LegendValue.propTypes = {
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sx: PropTypes.object,
  inactive: PropTypes.bool,
  onClick: PropTypes.func,
  handleHover: PropTypes.func,
  value: PropTypes.string,
  textVariant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ]),
  isLine: PropTypes.bool
};

LegendValue.defaultProps = {
  color: null,
  onClick: undefined,
  handleHover: () => {},
  inactive: false,
  sx: {},
  isLine: false,
  value: '',
  textVariant: 'caption'
};

export default LegendValue;
