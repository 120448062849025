import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { checkUserHasAccess } from '@helpers';
import { loginUser, verifyTokenValid } from '@api';
import { initializeAppInsights, updateTelemetryInitializer } from '@hooks';

const USER_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

const initialState = {
  user: null,
  hasAccess: false,
  isLoggedIn: false,
  error: null,
  status: USER_STATUS.IDLE
};

export const fetchUserFromToken = createAsyncThunk(
  'auth/verifyToken',
  async (signal, { rejectWithValue }) => {
    try {
      return await verifyTokenValid(signal);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleLoginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ signal, email, password }, { rejectWithValue }) => {
    try {
      return await loginUser(signal, email, password);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    set_user: (state, action) => {
      if (!['esgian', 'esgian demo'].includes(action.payload.profile.company?.toLowerCase())) {
        initializeAppInsights();
        updateTelemetryInitializer(action.payload);
      }
      localStorage.setItem('user-id', action.payload.id);
      state.status = USER_STATUS.SUCCEEDED;
      state.user = action.payload;
      state.hasAccess = checkUserHasAccess(action.payload);
      state.isLoggedIn = true;
    },
    set_unauthorised_user: (state, action) => {
      state.status = USER_STATUS.FAILED;
      state.user = null;
      state.hasAccess = false;
      state.isLoggedIn = false;
      state.error = action.payload;
    },
    reset_user: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserFromToken.pending, (state) => {
        state.status = USER_STATUS.LOADING;
      })
      .addCase(fetchUserFromToken.fulfilled, (state, action) => {
        AuthSlice.caseReducers.set_user(state, action);
      })
      .addCase(fetchUserFromToken.rejected, (state, action) => {
        AuthSlice.caseReducers.set_unauthorised_user(state, action);
      })
      .addCase(handleLoginUser.pending, (state) => {
        state.status = USER_STATUS.LOADING;
      })
      .addCase(handleLoginUser.fulfilled, (state, action) => {
        AuthSlice.caseReducers.set_user(state, action);
      })
      .addCase(handleLoginUser.rejected, (state, action) => {
        AuthSlice.caseReducers.set_unauthorised_user(state, action);
      });
  }
});

export const getUser = (state) => state.auth.user;
export const getUserStatus = (state) => state.auth.status;
export const userIsEsgian = (state) => {
  return (
    state.auth.user.profile.company === 'Esgian' || state.auth.user.profile.company === 'esgian'
  );
};
export const isUserLoading = (state) =>
  state.auth.status === USER_STATUS.LOADING || state.auth.status === USER_STATUS.IDLE;
export const userAccess = (state) => state.auth.hasAccess;

export const { setUser, set_unauthorised_user, reset_user } = AuthSlice.actions;

export default AuthSlice.reducer;
