import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@esgian/esgianui';

function RangeButton({ selectedValue, children, value, onClick, id }) {
  return (
    <Typography
      variant={'body2'}
      onClick={() => onClick(value)}
      color={selectedValue.index === value.index ? 'primary' : 'text.primary'}
      sx={{
        cursor: 'pointer',
        textDecoration: selectedValue.index === value.index ? 'underline' : 'none',
        textDecorationColor: ({ palette }) => palette.primary.dark,
        textUnderlineOffset: '2px'
      }}
      id={id}>
      {children}
    </Typography>
  );
}

RangeButton.propTypes = {
  value: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedValue: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.string
};

RangeButton.defaultProps = {
  selectedValue: {},
  value: undefined,
  id: ''
};

export default RangeButton;
