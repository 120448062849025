import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Stack, Typography } from '@esgian/esgianui';

function InsightDisplayValue({ loading, value, title, subtitle, trend, displayArrow, id }) {
  return (
    <Stack sx={{ p: 2 }} id={id}>
      {typeof title === 'string' ? <Typography variant={'body2'}>{title}</Typography> : title}

      {loading ? (
        <Skeleton height={'3em'} />
      ) : (
        <Stack direction={'row'} spacing={1} sx={{ textWrap: 'nowrap' }}>
          <Typography variant={'h5'} bold>
            {value}
          </Typography>
          {displayArrow && (
            <Stack direction={'row'} sx={{ alignItems: 'flex-end' }}>
              <Typography
                sx={{
                  lineHeight: 1.5,
                  color: ({ palette }) => {
                    if (trend?.color === 'disabled') {
                      return palette.text.disabled;
                    }
                    return palette[trend?.color]?.main;
                  }
                }}
                variant={'subtitle1'}>
                {trend?.percentageChange}
              </Typography>
              {trend?.arrow}
            </Stack>
          )}
        </Stack>
      )}
      <Typography variant={'body2'}>Last {subtitle}</Typography>
    </Stack>
  );
}

InsightDisplayValue.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.string,
  trend: PropTypes.any,
  displayArrow: PropTypes.bool,
  id: PropTypes.string
};

InsightDisplayValue.defaultProps = {
  loading: false,
  value: '',
  title: '',
  subtitle: '',
  trend: {},
  displayArrow: false,
  id: ''
};

export default InsightDisplayValue;
