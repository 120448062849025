import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon, Button, Paper, Stack, Typography } from '@esgian/esgianui';
import { DrillingEquipmentChart } from '@components/Charts';
import { DISPLAY_UNIT, EQUIPMENT_TYPES, SUB_SECTIONS } from '@constants';
import { useDisplayUnit, useUpdateSection } from '@hooks';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';
import { getValue, parseNumberToSignificant } from '@helpers';

function DrillingEquipment() {
  const { updateMainPageSection } = useUpdateSection();
  const { rigEquipmentQuery } = useMainPageQueries();
  const { getUnitTitle, displayUnit, convertUnitValue } = useDisplayUnit();

  const {
    series = [],
    unit = '',
    categories = []
  } = useMemo(() => {
    if (!rigEquipmentQuery.data) return {};
    const { clarifyData } = rigEquipmentQuery.data;
    let max = 0;
    let tempCat = [];
    let data = EQUIPMENT_TYPES.map(({ key, name }) => {
      let data = clarifyData[`${key}${displayUnit}`];
      if (!data) return null;
      tempCat.push(name);
      let tot = Object.values(data).reduce((a, b) => a + getValue(b), 0);
      max = max > tot ? max : tot;
      return tot;
    }).filter((val) => val !== null);
    const { title, converted } = getUnitTitle(max);
    data = data.map((d) =>
      converted ? convertUnitValue(d, displayUnit) : parseNumberToSignificant(d)
    );
    return {
      series: [{ name: 'Equipment consumed energy', data: data }],
      unit: title,
      categories: tempCat
    };
  }, [displayUnit, rigEquipmentQuery]);

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Total CO2e emissions in period';
      case DISPLAY_UNIT.NOX:
        return 'Total NOx emissions in period';
      case DISPLAY_UNIT.FUEL:
        return 'Total fuel consumption in period';
      case DISPLAY_UNIT.ENERGY:
        return 'Total energy consumption in period';
    }
  }, [displayUnit]);
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack>
        <TextWithTooltipIcon
          tooltipText={
            'Total consumption or emissions in the selected time period for each equipment.'
          }
          label={<Typography variant={'h6'}>Equipment</Typography>}
        />
        <Typography variant={'body2'}>{getSubTitle}</Typography>
      </Stack>
      <DrillingEquipmentChart
        categories={categories}
        loading={rigEquipmentQuery.isFetching}
        series={series}
        primaryXAxisLabel={unit}
      />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          onClick={() => updateMainPageSection(SUB_SECTIONS.EQUIPMENT)}
          endIcon={<ArrowForwardIcon />}
          variant={'outlined'}>
          See equipment page
        </Button>
      </Stack>
    </Paper>
  );
}

DrillingEquipment.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

DrillingEquipment.defaultProps = {
  loading: false,
  overviewData: null
};

export default DrillingEquipment;
